@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-cards {
  .more-items {
    padding: rem-calc(36 0 28 0);
  }

  .fil-standard-card-wrapper {
    padding-bottom: rem-calc($component-padding-base);
    height: 100%;

    fil-card-content {
      &:hover .fil-card-content__title {
        color: var(--fil-card-headline, var(--fil-color-base-grey-800));
      }
    }

    &.mini-promo {
      cursor: pointer;
      padding: 0;
      text-align: left;
      min-height: rem-calc(143);
      overflow: hidden;

      @include breakpoint(medium down) {
        margin-bottom: rem-calc(23);
      }

      &:hover .cta-section a {
        color: $grey-900;
      }

      .image {
        min-height: rem-calc(143);
        width: rem-calc(112);
        padding: rem-calc(15) 0;
        background-repeat: no-repeat;
        background-position: 50%;

        img {
          width: 100%;
        }
      }

      .text {
        position: relative;
        padding: rem-calc(15 20);

        h5 {
          margin-bottom: rem-calc(5);
        }

        p {
          font-size: rem-calc(13);
          line-height: rem-calc(19);
          min-height: auto;
          text-align: left;
        }

        a {
          left: rem-calc(20);
          bottom: rem-calc(15);

          span {
            top: rem-calc(-1);
          }
        }
      }

      .cta-section {
        left: rem-calc(20);
        width: auto;
        bottom: rem-calc(16);
        line-height: 1;

        a {
          margin-bottom: 0;
          font-size: rem-calc(16);
        }

        @include breakpoint(small only) {
          position: relative;
          left: 0;
          margin-bottom: 0;
          top: rem-calc(4);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:164";
@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-media-grid-wrapper {
  padding: rem-calc($component-padding-base 0);

  .cell {
    fil-text-media-block-g {
      display: block;
      text-align: left;

      + fil-text-media-block-g {
        margin-top: rem-calc($component-padding-base);
      }

      .fil-media-grid-content {
        a {
          display: inline;
          text-decoration: underline;
        }
      }
    }

    a:last-of-type {
      margin-top: rem-calc(20);
    }
  }
}

@include breakpoint(small only) {
  .fil-media-grid-wrapper {
    .cell {
      &:first-of-type {
        padding-left: 0;
        padding-right: 0;
      }

      &:nth-of-type(2) {
        padding-left: 0;
      }
    }

    .text-left {
      &:first-of-type {
        padding-left: 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:172";
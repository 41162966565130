@use "@fil-global/gds-core/scss/utils/bootstrap" as *;

.fil-article-grid {
  .articles {
    .cell {
      margin-bottom: rem-calc(30);

      fil-card-footer {
        .date {
          margin-left: rem-calc(86);
          margin-top: rem-calc(-18);
          color: $grey-500;
        }
      }
    }
  }

  fil-card-content {
    a {
      h4 {
        strong {
          color: $black;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:173";
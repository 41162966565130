@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-messaging {
  margin: rem-calc($component-padding-base 0);

  &.bg-grey-25 {
    padding: rem-calc($component-padding-base 0);
    margin-top: 0;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:168";
@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-article-list--compact {
  &.bg-grey-25 {
    .link-list {
      background: $white;
    }
  }

  .link-list {
    border-radius: rem-calc(4);
    border: rem-calc(1) solid $grey-50;
    margin-bottom: 0;
    margin-left: 0;
    padding: rem-calc(30 23 30 43);

    @include breakpoint(medium) {
      padding: rem-calc(38 40 38 57);
    }

    .link-item {
      a {
        display: block;
        width: 100%;
      }

      .label-pro-spacing {
        display: inline;
        margin-right: rem-calc(6);
      }

      .date {
        margin-right: rem-calc(10);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .label-pro {
        max-width: 100%;
        padding: rem-calc(5.5 9.5);
        overflow: hidden;
        border: rem-calc(1) solid transparent;
        border-radius: rem-calc(2);
        font-size: rem-calc(14);
        letter-spacing: rem-calc(.4);
        line-height: 1;
        text-overflow: ellipsis;
        vertical-align: middle;
        background: $blue-200;
        white-space: nowrap;
        color: $white;
        font-weight: 700;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:161";
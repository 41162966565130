@use "@fil-global/gds-core/scss/utils/bootstrap" as *;

.fil-enhanced-list-wrapper {
  padding: rem-calc($component-padding-base 0);

  .yext-wrapper {
    .search-form-enhanced-list {
      padding: rem-calc(20 50);

      .yxt-SearchBar-container {
        border-radius: 0;

        .yxt-SearchBar-autocomplete {
          max-width: inherit;
        }
      }

      .yxt-SearchBar-button {
        padding: rem-calc(0 12 14);
      }

      .yxt-SearchBar-voiceSearch {
        padding: rem-calc(0 0 14);
      }

      .yxt-SearchBar-clear {
        padding: rem-calc(0 12 14);
      }
    }
  }

  .fil-accordion & {
    padding: 0;
  }

  ul {
    &.fil-enhanced-list {
      > .fil-text-container {
        .cell {
          margin-left: 0;
        }
      }

      li {
        &.user-icon {
          padding-left: rem-calc(44);

          @include breakpoint(large) {
            padding-left: rem-calc(48);
          }

          fil-icon {
            position: absolute;
            left: 0;
            width: rem-calc(20);
            height: rem-calc(20);

            @include breakpoint(large) {
              width: rem-calc(24);
              height: rem-calc(24);
            }
          }
          //remove external icon in enhanced-list
          &::before {
            content: unset;
          }
        }
      }
    }

    &.link-list {
      li {
        a {
          display: block;
        }

        .tag {
          display: inline-block;
          margin-top: rem-calc(5);
          background: $white;
          font-size: rem-calc(15);
          padding: rem-calc(5 10);
          border: rem-calc(1) solid;
          border-left: rem-calc(10) solid;

          &.blue-500 {
            border-color: $blue-500;
          }

          &.grey-500 {
            border-color: $grey-500;
          }

          &.teal-500 {
            border-color: $teal-500;
          }

          &.green-500 {
            border-color: $green-500;
          }

          &.yellow-500 {
            border-color: $yellow-500;
          }

          &.orange-500 {
            border-color: $orange-500;
          }

          &.purple-500 {
            border-color: $purple-500;
          }
        }

        fil-icon {
          &[icon="custom-circle-chevron-down"] {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &.task-list {
      padding: rem-calc(23 40 23 43);

      .cell {
        padding-left: 0;
        margin-bottom: 0;
      }

      .task-list-wrapper {
        margin-left: 0;
        margin-top: 0;
      }

      li {
        &.task-list-item {
          padding-top: rem-calc(15);
          padding-left: 0;
          margin-top: 0;
          margin-bottom: rem-calc(15);
        }

        &::before {
          display: none;
        }

        img {
          margin-right: rem-calc(15);
          width: rem-calc(25);
          height: rem-calc(25);
        }

        a {
          position: relative;
          top: rem-calc(2);
        }
      }
    }

    &.dynamic-list {
      li {
        &::before {
          display: none;
        }

        img {
          margin-right: rem-calc(15);
          width: rem-calc(25);
          height: rem-calc(25);
        }
      }
    }
  }
}

@include breakpoint(medium only) {
  .fil-enhanced-list-wrapper {
    .yext-wrapper {
      .search-form-enhanced-list {
        padding: rem-calc(0 50);

        .yxt-SearchBar-container {
          .yxt-SearchBar-autocomplete {
            max-width: inherit;
          }
        }
      }
    }

    ul {
      &.task-list {
        .cell:nth-of-type(3) {
          width: 100%;

          li {
            width: 50%;
            float: left;
          }
        }
      }
    }
  }
}

@include breakpoint(small only) {
  .fil-enhanced-list-wrapper {
    .yext-wrapper {
      .search-form-enhanced-list {
        padding-left: rem-calc(20);

        .yxt-SearchBar-container {
          .yxt-SearchBar-autocomplete {
            max-width: inherit;
          }
        }
      }
    }

    ul {
      &.task-list {
        padding-left: rem-calc(32);

        li {
          padding-left: 0;
        }
      }

      &.link-list {
        li {
          padding-left: rem-calc(32);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:166";
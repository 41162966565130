@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-article-tags-container {
  display: flex;
  justify-content: center;
  margin-bottom: rem-calc(44);
  flex-wrap: wrap;
  margin-top: rem-calc(34);

  .article-tag {
    padding: rem-calc(8 16);
    border: rem-calc(2) solid $grey-50;
    background-color: $grey-25;
    margin: rem-calc(0 4 20);

    a {
      color: $grey-800;
    }
  }

  @include breakpoint(small only) {
    margin-bottom: rem-calc(20);
  }
}

.fil-article-tags {
  padding: rem-calc($component-padding-base 0);

  .tag-name {
    color: $orange-700;
  }
}

;@import "sass-embedded-legacy-load-done:163";
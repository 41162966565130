@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-article-page {
  section {
    &.fil-media-grid {
      p,
      ul {
        text-align: left;
      }
    }

    &.fil-text {
      p,
      h6,
      table,
      ol {
        text-align: left;
      }

      p {
        margin: rem-calc(20 0) !important;
        text-align: left;
      }
    }
  }

  .card {
    .card-section.author {
      p.small {
        text-align: left;
      }
    }
  }

  .fil-article-sections__title,
  .fil-article-sections__list {
    text-align: left;
  }

  .article-detail-twocol {
    .article-bg-image-wrapper {
      position: absolute;
      width: 45%;
      right: 0;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      z-index: -1;
      overflow: hidden;

      @include breakpoint (small down) {
        display: none;
      }
    }

    .breadcrumb-sections {
      padding: rem-calc(30 0);

      @include breakpoint( small down ) {
        padding: 0;
      }
    }

    .bg-white {
      background: $white;
    }

    .article-heading {
      line-height: 1;
      font-size: rem-calc(64);
      padding-top: rem-calc(30);
    }

    .label-pro {
      max-width: 100%;
      padding: rem-calc(3 10);
      overflow: hidden;
      border: rem-calc(1) solid transparent;
      border-radius: rem-calc(2);
      font-size: rem-calc(14);
      letter-spacing: rem-calc(.4);
      line-height: 1;
      text-overflow: ellipsis;
      vertical-align: middle;
      background: $blue-200;
      white-space: nowrap;
      color: $white;
      font-weight: 700;
    }

    .pipe-divider {
      padding: rem-calc(0 15);
    }

    .article-author-image,
    .social_media_image {
      height: rem-calc(50);
      width: rem-calc(50);
      border-radius: 50%;
    }

    .article_social_media_icons_wrapper {
      position: absolute;
      left: 0;
      bottom: rem-calc(20);
      width: 100%;
      text-align: center;

      @include breakpoint(xlarge up) {
        position: absolute;
        left: rem-calc(-100);
        top: rem-calc(60);
        z-index: 1;
        width: auto;
      }
    }

    .article-main-content {
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(30);
      position: relative;
      background: $white;

      .grid-container {
        padding: 0;

        .medium-10.medium-centered {
          width: 100%;
        }

        .right-br {

          section.fil-cards {
            text-align: center;
          }

          section.fil-article-grid {
            text-align: center;
          }

          div,
          section {
            padding-left: 0;

            :not(.fil-icon):not(.card):not(tbody > tr) {
              &:last-child {
                border-bottom: 0;

                &:not(h2.title-keyline) {
                  @include breakpoint (medium down) {
                    padding: 0;
                  }
                }
              }
            }


            ul,
            ol {
              text-align: left;
            }

            &.card-section {
              padding-left: rem-calc(20);
              padding-right: rem-calc(20);

              @include breakpoint ( medium down ) {
                padding-left: 0;
                padding-right: 0;
              }
            }

            &.card {
              @include breakpoint( medium down ) {
                padding-left: rem-calc(24);
              }
            }
          }

          .title-keyline {
            text-align: center;
          }
        }

        .cell {
          ul,
          ol {
            text-align: left;
          }
        }
      }

      @include breakpoint(large down) {
        padding: rem-calc(20 5);
      }

      @include breakpoint( medium down ) {
        padding: rem-calc( 20 0 );
      }

      .right-br {
        border-right: rem-calc(2) solid $grey-80;
        padding-right: rem-calc(80);

        :not(.card) {
          .cms-plugin {
            &:last-child {
              border-bottom: 0;
            }
          }
        }

        @include breakpoint(large down) {
          border-right: 0;
          padding-right: 0;
        }
      }

      .left-br {
        padding: rem-calc(30);

        @include breakpoint (medium down) {
          padding: 0;
        }
      }

      .cell {

        &:nth-last-of-type(-n+2) {
          border-bottom: 0;
        }
      }
    }

    .article_social_media_icons {
      a {
        display: inline-block;
        padding: rem-calc(5);

        @include breakpoint(xlarge up) {
          display: block;
        }
      }
    }

    .article-body {
      padding-bottom: rem-calc(80);

      @include breakpoint (large down) {
        padding-bottom: rem-calc(60);
      }
    }
  }

  .article-header {
    padding-bottom: 0;
    border-bottom: 0;
    border-top: 0;
    padding-top: 0;
    text-align: center;

    .article_author_name {
      margin-bottom: 0;
    }

    .article-header__author-image {
      height: rem-calc(68);
    }

    @include author_avatar(60);

    .author__avatar--old-article {
      margin: 0 auto rem-calc(10);
    }

    .article-author-fullwidth h6,
    .article-author-fullwidth p {
      color: $grey-500;
      font-size: rem-calc(15);
      font-weight: bold;
    }

    .single-column-layout {
      .article-bg-image-wrapper {
        position: relative;
        width: 100%;
      }

      .breadcrumb-sections {
        background: $grey-50;
        padding: rem-calc(30 10);
      }

      .article-heading {
        margin: rem-calc(30) auto;
      }

      .label-pro {
        max-width: 100%;
        padding: rem-calc(3 10);
        overflow: hidden;
        border: rem-calc(1) solid transparent;
        border-radius: rem-calc(2);
        font-size: rem-calc(14);
        letter-spacing: rem-calc(.4);
        line-height: 1;
        text-overflow: ellipsis;
        vertical-align: middle;
        background: $blue-200;
        white-space: nowrap;
        color: $white;
        font-weight: 700;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:162";
@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

@mixin articleThemeMixin($themeColor) {
  .fil-article-grid,
  .fil-article-list {
    .title-keyline::after {
      background-color: $themeColor;
    }

    .card .category {
      color: $themeColor;
    }
  }
}

.theme-blue-500 {
  @include articleThemeMixin($blue-500);
}

.theme-purple-400 {
  @include articleThemeMixin($purple-400);
}

.theme-green-400 {
  @include articleThemeMixin($green-400);
}

.theme-teal-500 {
  @include articleThemeMixin($teal-500);
}

.theme-orange-400 {
  @include articleThemeMixin($orange-400);
}

;@import "sass-embedded-legacy-load-done:157";